import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://0f5f72f1a2f880465f08659f4cea2694@o552412.ingest.us.sentry.io/4507390034509824",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});